import {
  Button,
  Card,
  Chip,
  DataTableColumnHeader,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '../../components/flexyui';
import {
  filteredPreselectedZones,
  getPreselectedZones,
  getShippingRange,
  getZoneExtraPart,
  getZoneTitle
} from '../../utils/shipping';
import {
  CreatedByType,
  GetShippingProfileQuery,
  ShippingRate,
  ShippingZoneType,
  useAddShippingPincodeMutation,
  useAddShippingStateCountryMutation,
  useDeleteShippingMutation,
  useDeleteShippingRateMutation,
  useEnableShippingRateMutation,
  useShippingStateCountryQuery,
  useUpdateShippingPincodeMutation,
  useUpdateShippingStateCountryMutation,
  ZoneCode,
  ZoneCodeInput
} from '../../graphql';
import classNames from 'classnames';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { useNavigate, useParams } from 'react-router-dom';
import { Title } from '../../components/shared/dashboard-title/title';
import { ButtonWithInfo } from '../../components/flexyui/ButtonWithInfo';
import { ShippingDrawer } from '../../components/shipping-v2/shipping-drawer';
import ConfirmationDialog from '../../components/shared/confirmation-dialog/confirmation-dialog';
import { CountryStatePicker } from '../../components/shared/country-state-picker/country-state-picker';

type Props = {
  profileData: GetShippingProfileQuery['getShippingProfile'];
};

export const Zones: React.FC<Props> = ({ profileData }) => {
  const navigate = useNavigate();
  const { profileId } = useParams();
  const profileName = profileData.find((profile) => profile.id === profileId)?.name;

  const { data, loading, refetch } = useShippingStateCountryQuery({
    variables: { shippingProfileId: profileId || '' },
    fetchPolicy: 'no-cache'
  });
  const [addZone] = useAddShippingStateCountryMutation();
  const [updateZone] = useUpdateShippingStateCountryMutation();
  const [addZonePincode] = useAddShippingPincodeMutation();
  const [updateZonePincode] = useUpdateShippingPincodeMutation();
  const [deleteZone] = useDeleteShippingMutation();
  const [deleteRate] = useDeleteShippingRateMutation();
  const [changeRateStatus] = useEnableShippingRateMutation();

  const [preselectedZones, setPreselectedZones] = useState<Array<ZoneCode>>([]);
  const [editZoneDetails, setEditZoneDetails] = useState<any>(null);
  const [deleteZoneId, setDeleteZoneId] = useState<string | null>(null);
  const [editRateDetails, setEditRateDetails] = useState<any>(null);
  const [deleteRateDetails, setDeleteRateDetails] = useState<any>(null);
  const [showZoneDialog, setShowZoneDialog] = useState<boolean>(false);
  const [showRateDialog, setShowRateDialog] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const handleZoneAction = async (
    actionType: 'zone' | 'zonePincode',
    name: string,
    selectedDataOrResourceId: Array<ZoneCodeInput> | string
  ) => {
    try {
      const variables = {
        shippingProfileId: profileId as string,
        ...(editZoneDetails && { shippingId: editZoneDetails.id }),
        configs:
          actionType === 'zone'
            ? {
                name,
                zone_codes: selectedDataOrResourceId as Array<ZoneCodeInput>
              }
            : {
                name,
                resource_id: selectedDataOrResourceId as string
              }
      };

      const action = editZoneDetails
        ? actionType === 'zone'
          ? updateZone
          : updateZonePincode
        : actionType === 'zone'
          ? addZone
          : addZonePincode;

      await action({ variables });

      enqueueSnackbar(`Zone has been ${editZoneDetails ? 'updated' : 'created'} successfully!`, { variant: 'success' });

      await refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setShowZoneDialog(false);
    }
  };

  const handleDelete = async () => {
    try {
      if (deleteZoneId) {
        await deleteZone({
          variables: {
            shippingProfileId: profileId as string,
            shippingId: deleteZoneId
          }
        });
        enqueueSnackbar('Zone has been deleted successfully!', {
          variant: 'success'
        });
      }
      if (deleteRateDetails) {
        await deleteRate({
          variables: {
            shippingId: deleteRateDetails.zoneId,
            shippingRateId: deleteRateDetails.rateId
          }
        });
        enqueueSnackbar('Shipping rate has been deleted successfully!', {
          variant: 'success'
        });
      }
      await refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setDeleteZoneId(null);
      setDeleteRateDetails(null);
      setShowConfirmationDialog(false);
    }
  };

  const handleRateStatus = async (zoneId: string, rateId: string, value: boolean) => {
    try {
      await changeRateStatus({
        variables: {
          shippingId: zoneId,
          shippingRateId: rateId,
          configs: value
        }
      });
      enqueueSnackbar('Shipping rate status has been updated successfully!', {
        variant: 'success'
      });

      await refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (data?.getShipping) setPreselectedZones(getPreselectedZones(data.getShipping));
  }, [data]);

  const updatedPreselectedZones = editZoneDetails
    ? filteredPreselectedZones(preselectedZones, editZoneDetails?.zone_codes || [])
    : preselectedZones;

  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center justify-between gap-3">
          <div className="flex items-center gap-1.5">
            <Button
              size="icon"
              variant="ghost"
              className="hover:bg-gray-200 rounded-lg w-7 h-7 p-0.5"
              onClick={() => navigate('/shipping')}
            >
              <Unicons.UilArrowLeft className="text-[#2A324C]" />
            </Button>
            <Title title="Shipping Zones" />
          </div>

          {profileName && <Chip text={profileName} variant="success" />}
        </div>

        {(data?.getShipping?.length || 0) > 0 && (
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setEditZoneDetails(null);
              setShowZoneDialog(true);
            }}
          >
            <Unicons.UilPlus size={18} className="mr-2" />
            Add Shipping zone
          </Button>
        )}
      </div>

      {loading &&
        [0, 1].map((parentIndex) => (
          <Card key={parentIndex} className="mb-6 overflow-hidden">
            <div className="flex items-center justify-between px-4 py-5 border-b">
              <Skeleton className="w-[80%] sm:w-[350px] !m-0" />
              <Skeleton className="!m-0 h-5 w-5" />
            </div>

            <Table className="rounded-md overflow-hidden">
              <TableBody>
                {[...Array(parentIndex === 0 ? 3 : 2)].map((_, index) => (
                  <TableRow key={index}>
                    {[...Array(5)].map((cell) => (
                      <TableCell key={cell} className="border-l border-gray-100">
                        <div className="py-2 px-3 flex items-center gap-3">
                          <Skeleton className="w-full !m-0" />
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        ))}

      {data && (data?.getShipping?.length || 0) === 0 && (
        <div className="border border-muted px-4 py-4 mt-2 rounded-[6px]">
          <div className="text-center my-4">
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                setEditZoneDetails(null);
                setShowZoneDialog(true);
              }}
            >
              <Unicons.UilPlus size={18} className="mr-2" />
              Add Shipping zone
            </Button>
          </div>
        </div>
      )}

      {data?.getShipping.map((zone: any) => (
        <div className="rounded-md border bg-white mb-6">
          <table className="w-full caption-bottom text-sm">
            <TableHeader className="w-full">
              <TableRow className="flex flex-grow">
                <div className="w-full px-4 py-4 flex items-center justify-between">
                  <div className="flex items-center">
                    {zone.type === ShippingZoneType.StateCountry ? (
                      zone?.zone_codes?.length > 1 ? (
                        <Unicons.UilGlobe size={26} className="mr-2" />
                      ) : (
                        <div className="rounded overflow-hidden mr-3 flex" style={{ width: '33px', height: '23px' }}>
                          <img
                            src={`https://flagcdn.com/${zone?.zone_codes?.[0].country_code.toLowerCase()}.svg`}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover'
                            }}
                            alt={`${zone?.zone_codes?.[0].country} flag`}
                          />
                        </div>
                      )
                    ) : (
                      <Unicons.UilMapPinAlt size={26} className="mr-2" />
                    )}
                    <div className="font-medium mr-2">{zone.name}</div>
                    {!!getZoneTitle(zone) && (
                      <>
                        <div className="mr-2">•</div>
                        <div>{getZoneTitle(zone)}</div>
                      </>
                    )}
                    {getZoneExtraPart(zone)?.length > 0 && (
                      <>
                        {','}
                        <ButtonWithInfo
                          heading={getZoneExtraPart(zone)?.join(', ')}
                          description=""
                          simple={true}
                          side="bottom"
                          className={classNames('!py-2 !px-3 !font-normal', {
                            'w-[100px]': getZoneExtraPart(zone)?.length === 2,
                            'w-[200px]': getZoneExtraPart(zone)?.length === 4,
                            'w-[300px]': getZoneExtraPart(zone)?.length > 4
                          })}
                        >
                          <div className="cursor-pointer underline ml-1">{getZoneExtraPart(zone)?.length} more</div>
                        </ButtonWithInfo>
                      </>
                    )}
                  </div>
                  <DropdownMenu>
                    <DropdownMenuTrigger className="focus:outline-0">
                      <Unicons.UilEllipsisH
                        size={24}
                        className="py-1 text-[#595F74] cursor-pointer rounded-lg hover:bg-gray-200"
                      />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-36 !z-[1500]" sideOffset={0} align="end">
                      <DropdownMenuItem
                        onClick={() => {
                          setEditRateDetails({ zoneId: zone.id, zoneType: zone.type, data: null });
                          setShowRateDialog(true);
                        }}
                      >
                        Add rate
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        disabled={zone.created_by === CreatedByType.Shopify}
                        onClick={() => {
                          setEditZoneDetails(zone);
                          setShowZoneDialog(true);
                        }}
                      >
                        Edit zone
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          setDeleteZoneId(zone.id);
                          setShowConfirmationDialog(true);
                        }}
                      >
                        <div className="text-red-500">Delete zone</div>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </TableRow>
            </TableHeader>
          </table>
          <Table className="rounded-md overflow-hidden">
            {(zone?.shipping_rates?.length || 0) > 0 ? (
              <TableHeader>
                <TableRow>
                  <TableHead className="bg-[#F3F4F5] text-[#595F74]">
                    <DataTableColumnHeader title="Rate name" />
                  </TableHead>
                  <TableHead className="bg-[#F3F4F5] text-[#595F74]">
                    <DataTableColumnHeader title="Estimated delivery time" />
                  </TableHead>
                  <TableHead className="bg-[#F3F4F5] text-[#595F74]">
                    <DataTableColumnHeader title="Range" />
                  </TableHead>
                  <TableHead className="bg-[#F3F4F5] text-[#595F74]">
                    <DataTableColumnHeader title="Active" className="flex justify-center" />
                  </TableHead>
                  <TableHead className="bg-[#F3F4F5] text-[#595F74]">
                    <DataTableColumnHeader title="Actions" className="flex justify-center" />
                  </TableHead>
                </TableRow>
              </TableHeader>
            ) : (
              <TableRow className="border-0">
                <TableCell colSpan={5} className="h-24 text-center">
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      setEditRateDetails({ zoneId: zone.id, zoneType: zone.type, data: null });
                      setShowRateDialog(true);
                    }}
                  >
                    <Unicons.UilPlus size={18} className="mr-2" />
                    Add rate
                  </Button>
                </TableCell>
              </TableRow>
            )}
            <TableBody>
              {zone?.shipping_rates?.map((rate: ShippingRate) => (
                <TableRow>
                  <TableCell className="border-l border-gray-100">
                    <div className="py-2 px-3 flex items-center gap-3">
                      <div>{rate.title}</div>
                      {rate.created_by === CreatedByType.Shopify && (
                        <ButtonWithInfo heading="Shopify" description="" simple={true} side="top">
                          <Chip
                            variant="simple"
                            className="text-[#696969] p-[5px]"
                            icon={<Unicons.UilImport size={13} />}
                          />
                        </ButtonWithInfo>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="border-l border-gray-100">
                    <div className="py-2 px-3">
                      {rate?.delivery_estimation?.length > 0 ? rate.delivery_estimation : '-'}
                    </div>
                  </TableCell>
                  <TableCell className="border-l border-gray-100">
                    <div className="py-2 px-3">{getShippingRange(rate.price_condition_rules, rate.type)}</div>
                  </TableCell>
                  <TableCell className="border-l border-gray-100">
                    <div className="flex py-2 px-3 justify-center items-center">
                      <Switch
                        checked={rate.enabled}
                        onCheckedChange={(value) => handleRateStatus(zone.id, rate.id, value)}
                      />
                    </div>
                  </TableCell>
                  <TableCell className="border-l border-gray-100">
                    <div className="py-1.5 px-3 flex justify-center items-center gap-2">
                      <Button
                        size="icon"
                        variant="icon"
                        disabled={rate.created_by === CreatedByType.Shopify}
                        onClick={() => {
                          setEditRateDetails({ zoneId: zone.id, zoneType: zone.type, data: rate });
                          setShowRateDialog(true);
                        }}
                      >
                        <Unicons.UilEdit size={22} className="p-[2px] text-[#595F74]" />
                      </Button>
                      <Button
                        size="icon"
                        variant="icon"
                        onClick={() => {
                          setDeleteRateDetails({ zoneId: zone.id, rateId: rate.id });
                          setShowConfirmationDialog(true);
                        }}
                        className="text-[#595F74] hover:text-red-500"
                      >
                        <Unicons.UilTrashAlt size={22} className="p-[2px]" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ))}

      <ShippingDrawer
        open={showRateDialog}
        setOpen={setShowRateDialog}
        shippingRateData={editRateDetails}
        refetch={refetch}
      />

      {showZoneDialog && (
        <CountryStatePicker
          open={showZoneDialog}
          setOpen={setShowZoneDialog}
          zoneDetails={editZoneDetails}
          preselectedZones={updatedPreselectedZones}
          onSave={(name, selectedData, resourceId) => {
            if (resourceId) handleZoneAction('zonePincode', name, resourceId);
            else handleZoneAction('zone', name, selectedData);
          }}
        />
      )}

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={handleDelete}
        onCancel={() => {
          setDeleteZoneId(null);
          setDeleteRateDetails(null);
        }}
        headerText={`Delete ${deleteZoneId ? 'zone' : ''}${deleteRateDetails ? 'rate' : ''}?`}
        text={`This action will delete the selected ${deleteZoneId ? 'zone' : ''}${deleteRateDetails ? 'shipping rate' : ''}. Are you sure you want to continue?`}
        confirmButtonText="Confirm"
        confirmActionVariant="destructive"
        loading={loading}
      />
    </>
  );
};
