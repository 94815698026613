/* eslint-disable camelcase */
import {
  PriceConditionRule,
  PricingConditionType,
  ShippingStateCountry,
  ShippingZoneType,
  ZoneCode
} from '../../graphql';

export const getShippingRange = (range: Array<PriceConditionRule>, type: any) => {
  if (type === PricingConditionType.None) return '-';

  const firstValue = range[0].minimum_value;
  const lastRule = range[range.length - 1];
  const upperLimit = lastRule.maximum_value || 'up';
  const separator = lastRule.maximum_value ? '-' : 'and';

  if (type === PricingConditionType.Price) {
    return upperLimit === 'up'
      ? `Orders from ₹${firstValue} and up`
      : `Orders from ₹${firstValue} ${separator} ₹${upperLimit}`;
  }
  if (type === PricingConditionType.Weight) {
    return upperLimit === 'up'
      ? `Orders from ${firstValue}kg and up`
      : `Orders from ${firstValue}kg ${separator} ${upperLimit}kg`;
  }
};

export const getZoneTitle = (zone: any) => {
  if (zone.type === ShippingZoneType.StateCountry) {
    if (!zone?.zone_codes) return '';

    return zone.zone_codes
      .slice(0, 3)
      .map((each: ZoneCode) => each.country.charAt(0).toUpperCase() + each.country.slice(1).toLowerCase())
      .join(', ');
  } else if (zone.type === ShippingZoneType.Pincode) {
    if (!zone?.count) return '';

    return `${zone.count} pincodes`;
  }
};

export const getZoneExtraPart = (zone: any) => {
  if (!zone?.zone_codes) return [];

  return zone.zone_codes
    .slice(3)
    .map((each: ZoneCode) => each.country.charAt(0).toUpperCase() + each.country.slice(1).toLowerCase());
};

export const filteredPreselectedZones = (
  preselectedZones: Array<ZoneCode>,
  selectedCountriesData: Array<ZoneCode>
): Array<ZoneCode> => {
  return preselectedZones
    .map((zone) => {
      const matchingCountry = selectedCountriesData.find((selected) => selected.country_code === zone.country_code);

      if (matchingCountry) {
        const filteredStates = zone.states.filter((state) => !matchingCountry.states.includes(state));
        return { ...zone, states: filteredStates };
      }

      return zone;
    })
    .filter(
      (zone) => zone.states.length > 0 || !selectedCountriesData.some((c) => c.country_code === zone.country_code)
    );
};

export const getPreselectedZones = (zones: ShippingStateCountry[]): Array<ZoneCode> => {
  const countryMap: { [key: string]: ZoneCode } = {};

  zones.forEach((zone) => {
    zone?.zone_codes?.forEach((zoneCode) => {
      const { country, country_code, states } = zoneCode;
      const key = `${country}-${country_code}`;

      if (!countryMap[key]) {
        countryMap[key] = {
          country,
          country_code,
          states: [...states]
        };
      } else {
        countryMap[key].states = Array.from(new Set([...countryMap[key].states, ...states]));
      }
    });
  });

  return Object.values(countryMap);
};
