// import jwt from "jsonwebtoken";
import _ from 'lodash';

import { path } from '../constants/path-title';

export const isValidEmail = (email: string): boolean => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export function removeTypeName(value: any) {
  _.forEach(_.keys(value), (val) => {
    if (typeof value[val] === 'object') {
      value[val] = removeTypeName(value[val]);
    }
  });

  if (value && value.__typename) {
    const { __typename, ...restValues } = value;
    return restValues;
  }
  return value;
}
