/* eslint-disable camelcase */
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  RadioGroup
} from '../../flexyui';
import debounce from 'debounce';
import classNames from 'classnames';
import { Countries } from '../../../constants/countries';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ShippingZoneType, ZoneCode } from '../../../graphql';
import React, { useCallback, useEffect, useState } from 'react';
import { ShippingPincode } from '../../shipping-v2/shipping-pincode';
import { RadioWithLabel } from '../../shared/radio-with-label/radio-with-label';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../flexyui/Accordion';

type Zone = {
  name: string;
  code: string;
  inAnotherZone: boolean;
};

type Country = {
  name: string;
  code: string;
  continent: string;
  phoneNumberPrefix: number;
  provinceKey: string;
  inAnotherZone: boolean;
  zones: Zone[];
};

type props = {
  open: boolean;
  setOpen: (data: any) => void;
  zoneDetails: any;
  preselectedZones: ZoneCode[];
  onSave: (name: string, selectedData: any, resourceId: string) => void;
};

export const CountryStatePicker: React.FC<props> = ({
  open,
  setOpen,
  zoneDetails,
  preselectedZones = [],
  onSave
}: any) => {
  const ITEMS_PER_PAGE = 40;

  const [name, setName] = useState<string>(zoneDetails?.name || '');
  const [type, setType] = useState<ShippingZoneType>(zoneDetails?.type || ShippingZoneType.StateCountry);
  const [resourceId, setResourceId] = useState<string | null>(null);
  const [selectedCountries, setSelectedCountries] = useState<ZoneCode[]>(zoneDetails?.zone_codes || []);
  const [countriesData, setCountriesData] = useState<Country[]>([]);
  const [visibleCountries, setVisibleCountries] = useState<Country[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const enrichedCountriesData = Countries.map((country) => {
      const isCountryInAnotherZone = preselectedZones.some(
        (zone: ZoneCode) => zone.country_code === country.code && zone.states.length === country.zones.length
      );
      const enrichedZones = country.zones.map((zone) => {
        const isZoneInAnotherZone = preselectedZones.some(
          (preselected: ZoneCode) => preselected.country_code === country.code && preselected.states.includes(zone.code)
        );
        return {
          ...zone,
          inAnotherZone: isZoneInAnotherZone
        };
      });

      return {
        ...country,
        inAnotherZone: isCountryInAnotherZone,
        zones: enrichedZones
      };
    });

    setCountriesData(enrichedCountriesData);
    resetPagination(enrichedCountriesData);
  }, [preselectedZones]);

  const handleCountryToggle = (countryName: string, countryCode: string) => {
    setSelectedCountries((prevState) => {
      const updatedSelections = [...prevState];
      const countryIndex = updatedSelections.findIndex((country) => country.country_code === countryCode);
      const countryZones =
        countriesData.find((country) => country.code === countryCode)?.zones?.filter((zone) => !zone.inAnotherZone) ||
        [];

      if (countryIndex !== -1) {
        if (updatedSelections[countryIndex].states.length < countryZones.length) {
          // If no states are selected or some states are selected, select the entire country
          updatedSelections[countryIndex].states = countryZones.map((zone) => zone.code);
        } else {
          // If the country is already fully selected, deselect it
          updatedSelections.splice(countryIndex, 1);
        }
      } else {
        // If the country is not found in the selectedCountries array, add it with all its zones selected
        updatedSelections.push({
          country: countryName,
          country_code: countryCode,
          states: countryZones.map((zone) => zone.code)
        });
      }

      return updatedSelections;
    });
  };

  const handleStateToggle = (countryName: string, countryCode: string, stateCode: string) => {
    setSelectedCountries((prevState) => {
      const updatedSelections = [...prevState];
      const countryIndex = updatedSelections.findIndex((country) => country.country_code === countryCode);

      if (countryIndex !== -1) {
        // Toggle the state selection for an existing country entry
        const isStateSelected = updatedSelections[countryIndex].states.includes(stateCode);

        updatedSelections[countryIndex].states = isStateSelected
          ? updatedSelections[countryIndex].states.filter((state) => state !== stateCode) // Remove the state if it is selected
          : [...updatedSelections[countryIndex].states, stateCode]; // Add the state if it is not selected

        // If no states are left after removing, remove the country entry as well
        if (updatedSelections[countryIndex].states.length === 0) {
          updatedSelections.splice(countryIndex, 1);
        }
      } else {
        // Add a new entry for the country with the selected state
        updatedSelections.push({
          country: countryName,
          country_code: countryCode,
          states: [stateCode]
        });
      }

      return updatedSelections;
    });
  };

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      setSearchQuery(query);
      resetPagination(countriesData, query);
    }, 0),
    [countriesData]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    debouncedSearch(event.target.value);
  };

  const resetPagination = (data: Country[], query = '') => {
    const filteredCountries =
      query.length > 0
        ? data.filter(
            (country) =>
              country.name.toLowerCase().includes(query.toLowerCase()) ||
              country.zones.some((zone) => zone.name.toLowerCase().includes(query.toLowerCase()))
          )
        : data;

    setVisibleCountries(filteredCountries.slice(0, ITEMS_PER_PAGE));
    setCurrentIndex(ITEMS_PER_PAGE);
    setHasMore(filteredCountries.length > ITEMS_PER_PAGE);
  };

  const loadMoreCountries = () => {
    console.log('called');
    const filteredCountries =
      searchQuery.length > 0
        ? countriesData.filter(
            (country) =>
              country.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
              country.zones.some((zone) => zone.name.toLowerCase().includes(searchQuery.toLowerCase()))
          )
        : countriesData;

    const nextCountries = filteredCountries.slice(currentIndex, currentIndex + ITEMS_PER_PAGE);
    setVisibleCountries((prev) => [...prev, ...nextCountries]);
    setCurrentIndex((prevIndex) => prevIndex + ITEMS_PER_PAGE);
    setHasMore(currentIndex + ITEMS_PER_PAGE < filteredCountries.length);
  };

  return (
    <Dialog open={open} onOpenChange={(value: boolean) => setOpen(value)}>
      <DialogContent className="!gap-0" size="sm" close={true}>
        <div>
          <DialogHeader>
            <DialogTitle>
              <p>{!zoneDetails ? 'Edit' : 'Create'} shipping zone</p>
            </DialogTitle>
            <DialogDescription className="p-5 !m-0">
              <div>
                <div className="text-[#595F74] mb-1 text-sm">Zone name</div>
                <Input
                  type="text"
                  className="text-black"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
                <div className="text-[#b5b5b5] mt-1 text-xs">Customers won’t see this</div>
              </div>
              {!zoneDetails && (
                <div className="mt-4">
                  <Label size="md" className="text-sm">
                    Type
                  </Label>
                  <RadioGroup defaultValue={ShippingZoneType.StateCountry} className="flex flex-col gap-0 mt-1.5">
                    <RadioWithLabel
                      label="State & country based"
                      value={ShippingZoneType.StateCountry}
                      onClick={() => setType(ShippingZoneType.StateCountry)}
                    />
                    <RadioWithLabel
                      label="Pincode based"
                      value={ShippingZoneType.Pincode}
                      onClick={() => setType(ShippingZoneType.Pincode)}
                    />
                  </RadioGroup>
                </div>
              )}
              {type === ShippingZoneType.StateCountry && (
                <div className="mt-4">
                  <Label size="md" className="text-sm">
                    Shipping zones
                  </Label>
                  <div
                    id="scrollableDiv"
                    className="w-full h-[40vh] mt-1 overflow-y-auto scroll-smooth rounded-md border border-[#E3E3E3]"
                  >
                    <div className="sticky z-10 bg-white top-0 !m-0 border-b-[1px]">
                      <Input
                        type="text"
                        placeholder="Search for a country or state"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="!m-0 !border-0 focus-visible:ring-0 focus-visible:ring-offset-0 text-black"
                      />
                    </div>
                    {visibleCountries.length > 0 ? (
                      <>
                        <Accordion type="multiple">
                          <InfiniteScroll
                            dataLength={visibleCountries.length}
                            next={loadMoreCountries}
                            hasMore={hasMore}
                            loader={''}
                            scrollableTarget="scrollableDiv"
                          >
                            {visibleCountries.map((country: Country) => (
                              <AccordionItem value={country.code} key={country.code}>
                                <AccordionTrigger
                                  showChevron={country.zones.length > 0}
                                  className={classNames('py-0 border-b-[1px] cursor-pointer px-2 sm:px-4', {
                                    'bg-[#f7f7f7]': country.inAnotherZone
                                  })}
                                >
                                  <div className="w-[100%] h-[60px] flex items-center">
                                    <Checkbox
                                      checked={
                                        country.zones.length === 0
                                          ? selectedCountries.findIndex(
                                              (selected) => selected.country_code === country.code
                                            ) !== -1
                                          : (selectedCountries.find(
                                              (selected) => selected.country_code === country.code
                                            )?.states.length ?? 0) === country.zones.length
                                      }
                                      onClick={(e) => e.stopPropagation()}
                                      onChange={() => handleCountryToggle(country.name, country.code)}
                                      disabled={country.inAnotherZone}
                                    />
                                    <div
                                      className="rounded overflow-hidden ml-4"
                                      style={{ width: '35px', height: '25px' }}
                                    >
                                      <img
                                        src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          objectFit: 'cover'
                                        }}
                                        alt={`${country.name} flag`}
                                      />
                                    </div>
                                    <div className="ml-4 text-gray-700 flex flex-1 items-center justify-between">
                                      <div>{country.name}</div>
                                      {country.inAnotherZone ? (
                                        <div className="text-[#b5b5b5] text-[13px] pr-2">in another zone</div>
                                      ) : (
                                        country.zones.length > 0 && (
                                          <div className="pr-2">
                                            {selectedCountries.find(
                                              (selected) => selected.country_code === country.code
                                            )?.states.length || 0}{' '}
                                            of {country.zones.length}{' '}
                                            {country.provinceKey.split('_').join(' ').toLowerCase()}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </AccordionTrigger>

                                <AccordionContent className="!py-0">
                                  {country?.zones?.map((zone: any) => (
                                    <div
                                      className={classNames(
                                        'w-[100%] h-[45px] flex items-center border-b-[1px] cursor-pointer hover:bg-[#f7f7f7] pl-12 pr-4 sm:pl-14 sm:pr-6',
                                        {
                                          'bg-[#f7f7f7] cursor-not-allowed': zone.inAnotherZone
                                        }
                                      )}
                                      onClick={() => {
                                        if (!zone.inAnotherZone)
                                          handleStateToggle(country.name, country.code, zone.code);
                                      }}
                                      key={zone.name}
                                    >
                                      <Checkbox
                                        checked={
                                          selectedCountries
                                            .find((selected) => selected.country_code === country.code)
                                            ?.states.includes(zone.code) || false
                                        }
                                        disabled={zone.inAnotherZone}
                                      />
                                      <div className="ml-2 text-gray-700 flex flex-1 items-center justify-between">
                                        <div>{zone.name}</div>
                                        {zone.inAnotherZone && (
                                          <div className="text-[#b5b5b5] text-[13px]">in another zone</div>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </AccordionContent>
                              </AccordionItem>
                            ))}
                          </InfiniteScroll>
                        </Accordion>
                      </>
                    ) : (
                      !!searchQuery && <div className="p-4">No Results found for "{searchQuery}"</div>
                    )}
                  </div>
                </div>
              )}
              {type === ShippingZoneType.Pincode && (
                <ShippingPincode level="ZONE" details={zoneDetails} setResourceId={setResourceId} />
              )}
            </DialogDescription>
          </DialogHeader>
        </div>
        <DialogFooter>
          <Button variant="outline" size="md" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="default"
            size="md"
            disabled={!name || (type === ShippingZoneType.StateCountry ? selectedCountries.length === 0 : !resourceId)}
            onClick={() => {
              const updatedselectedCountries = selectedCountries.map(({ country, country_code, states }) => ({
                country,
                country_code,
                state_codes: states
              }));
              onSave(name, updatedselectedCountries, resourceId);
              setOpen(false);
            }}
          >
            Done
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
