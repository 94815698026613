import React from 'react';
import classNames from 'classnames';
import { ShippingFields } from './shipping-drawer';
import * as Unicons from '@iconscout/react-unicons';
import { FieldArray, useFormikContext } from 'formik';
import { pricingRange } from '../../constants/shipping';
import { Button, Checkbox, Input, Label, Separator } from '../flexyui';

export const ShippingDrawerRules = () => {
  const { values, touched, errors, getFieldProps, setFieldValue } = useFormikContext<ShippingFields>();

  const handleInputChange = (e: any, index: number, fieldName: string, ruleField: boolean) => {
    const value = e.target.value;
    let regex;

    if (ruleField) {
      if (values?.type === 'PRICE') regex = /^\d*\.?\d{0,2}$/;
      else if (values?.type === 'WEIGHT') regex = /^\d*\.?\d{0,3}$/;
    } else regex = /^\d*\.?\d{0,2}$/;

    if (regex && regex.test(value)) {
      const updatedValue = value ? Number(value) : '';
      setFieldValue(fieldName, updatedValue);

      if (fieldName.includes('maximum_value') && values.price_condition_rules[index + 1]) {
        const stepValue = values?.type === 'PRICE' ? 0.01 : 0.001;
        const nextMinimumValue = (updatedValue || 0) + stepValue;
        const roundedNextMinimumValue = Number(nextMinimumValue.toFixed(values?.type === 'PRICE' ? 2 : 3));

        setFieldValue(`price_condition_rules[${index + 1}].minimum_value`, roundedNextMinimumValue);
      }
    }
  };

  const handleRemoveRule = (index: number) => {
    const updatedRules = values.price_condition_rules.filter((_, i) => i !== index);

    for (let i = 1; i < updatedRules.length; i++) {
      const prevMaximumValue = updatedRules[i - 1].maximum_value;
      if (prevMaximumValue !== null) {
        const stepValue = values?.type === 'PRICE' ? 0.01 : 0.001;
        const nextMinimumValue = prevMaximumValue + stepValue;

        updatedRules[i].minimum_value = Number(nextMinimumValue.toFixed(values?.type === 'PRICE' ? 2 : 3));
      }
    }

    setFieldValue('price_condition_rules', updatedRules);
  };

  return (
    <FieldArray name="price_condition_rules">
      {({ push }) => (
        <>
          <div className="my-4 font-semibold border rounded-lg">
            <div className="flex items-center border-b mb-4">
              {pricingRange(values.type)?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={classNames('text-[#595F74] font-medium p-4', {
                      '!w-[150px] -mr-[5px] sm:mr-[27px]': index === 0,
                      'mr-[5px]': index === 1,
                      'hidden sm:block': index === 2
                    })}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
            <div className="border-1 last:rounded-b-lg">
              {values.price_condition_rules.map((rule, index) => (
                <div key={`${values.price_condition_rules.length}-${index}`}>
                  {index !== 0 && <Separator />}
                  <div className="flex justify-between items-center px-4 font-normal">
                    <div className="max-w-[138px]">
                      <Input
                        {...getFieldProps(`price_condition_rules[${index}].minimum_value`)}
                        onChange={(e: any) =>
                          handleInputChange(e, index, `price_condition_rules[${index}].minimum_value`, true)
                        }
                        disabled={index > 0}
                        error={
                          touched.price_condition_rules?.[index]?.minimum_value &&
                          !!(errors as any).price_condition_rules?.[index]?.minimum_value
                        }
                        type="number"
                        placeholder="Min value"
                        iconPosition={values.type === 'PRICE' ? 'start' : 'end'}
                        icon={
                          values.type === 'PRICE' ? (
                            <Unicons.UilRupeeSign width={18} height={18} className="mr-3 text-[#2A324C]" />
                          ) : (
                            <p className="text-[#2A324C] font-medium">Kg</p>
                          )
                        }
                      />
                    </div>
                    <div>
                      <Unicons.UilMinus width={18} height={18} className="text-[#2A324C]" />
                    </div>
                    <div className="max-w-[138px]">
                      <Input
                        {...getFieldProps(`price_condition_rules[${index}].maximum_value`)}
                        onChange={(e: any) =>
                          handleInputChange(e, index, `price_condition_rules[${index}].maximum_value`, true)
                        }
                        error={
                          touched.price_condition_rules?.[index]?.maximum_value &&
                          !!(errors as any).price_condition_rules?.[index]?.maximum_value
                        }
                        type="number"
                        placeholder="Max value"
                        iconPosition={values.type === 'PRICE' ? 'start' : 'end'}
                        icon={
                          values.type === 'PRICE' ? (
                            <Unicons.UilRupeeSign width={18} height={18} className="mr-3 text-[#2A324C]" />
                          ) : (
                            <p className="text-[#2A324C] font-medium">Kg</p>
                          )
                        }
                      />
                    </div>
                    <div className="hidden sm:block max-w-[138px]">
                      <Input
                        {...getFieldProps(`price_condition_rules[${index}].delivery_price`)}
                        onChange={(e: any) =>
                          handleInputChange(e, index, `price_condition_rules[${index}].delivery_price`, false)
                        }
                        error={
                          touched.price_condition_rules?.[index]?.delivery_price &&
                          !!(errors as any).price_condition_rules?.[index]?.delivery_price
                        }
                        type="number"
                        placeholder="Charges"
                        icon={<Unicons.UilRupeeSign width={18} height={18} className="mr-3 text-[#2A324C]" />}
                      />
                    </div>
                    <Button
                      size="icon"
                      variant="icon"
                      disabled={values.price_condition_rules.length === 1}
                      onClick={() => handleRemoveRule(index)}
                      className="ml-2 sm:ml-0"
                    >
                      <Unicons.UilTimes className="text-[#000] cursor-pointer" />
                    </Button>
                  </div>
                  <div className="mt-4">
                    <div className="px-4 mb-4 sm:hidden">
                      <div className="text-[#595F74] font-medium mb-1.5">Shipping Charges</div>
                      <div className="max-w-[138px]">
                        <Input
                          {...getFieldProps(`price_condition_rules[${index}].delivery_price`)}
                          onChange={(e: any) =>
                            handleInputChange(e, index, `price_condition_rules[${index}].delivery_price`, false)
                          }
                          error={
                            touched.price_condition_rules?.[index]?.delivery_price &&
                            !!(errors as any).price_condition_rules?.[index]?.delivery_price
                          }
                          type="number"
                          placeholder="Charges"
                          icon={<Unicons.UilRupeeSign width={18} height={18} className="mr-3 text-[#2A324C]" />}
                        />
                      </div>
                    </div>
                    <div className="items-center flex ml-5 mb-4">
                      <Checkbox
                        {...getFieldProps(`price_condition_rules[${index}].cod_accepted`)}
                        id={`price_condition_rules[${index}].cod_accepted`}
                        checked={values.price_condition_rules[index].cod_accepted}
                        onChange={(e) =>
                          setFieldValue(`price_condition_rules[${index}].cod_accepted`, e.target.checked)
                        }
                      />
                      <div className="leading-none ml-3">
                        <Label htmlFor={`price_condition_rules[${index}].cod_accepted`} className="cursor-pointer">
                          Accept cash on delivery
                        </Label>
                      </div>
                    </div>
                    {values.price_condition_rules[index].cod_accepted && (
                      <div className="max-w-[180px] ml-5 mb-5">
                        <Input
                          {...getFieldProps(`price_condition_rules[${index}].cod_charges`)}
                          onChange={(e: any) =>
                            handleInputChange(e, index, `price_condition_rules[${index}].cod_charges`, false)
                          }
                          error={
                            touched.price_condition_rules?.[index]?.cod_charges &&
                            !!(errors as any).price_condition_rules?.[index]?.cod_charges
                          }
                          type="number"
                          placeholder="COD Charges"
                          icon={<Unicons.UilRupeeSign width={18} height={18} className="mr-3 text-[#2A324C]" />}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <Button
              variant="outline"
              size="sm"
              disabled={
                !!errors.price_condition_rules ||
                !values.price_condition_rules[values.price_condition_rules.length - 1].maximum_value
              }
              onClick={() => {
                const stepValue = values?.type === 'PRICE' ? 0.01 : 0.001;
                const nextMinimumValue =
                  values.price_condition_rules[values.price_condition_rules.length - 1].maximum_value! + stepValue;
                const roundedNextMinimumValue = Number(nextMinimumValue.toFixed(values?.type === 'PRICE' ? 2 : 3));

                push({
                  minimum_value: roundedNextMinimumValue,
                  maximum_value: null,
                  delivery_price: 0,
                  cod_charges: null,
                  cod_accepted: false
                });
              }}
            >
              Add Rate
            </Button>
          </div>
        </>
      )}
    </FieldArray>
  );
};
